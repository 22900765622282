import { useTranslation } from 'react-i18next';
import constants from '../../../../../components/entity/constants';
import { createCallbackField, createField } from '../../../../../components/helpers/fieldHelper';
import { useVolcanoApiClient } from '../../../../../context/VolcanoApiClientProvider';
import CollectionDataTable from '../../../../../components/Datatable/CollectionDataTable';

const IntermediaryEntityActionLogs = ({ entity }) => {
    const {apiClient} = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    const fetcher = () => {
        return apiClient.intermediary.getEntityActionLogs(entity.id)
    }

    const columns = [
        createField(
            "created",
            t("bookings.fields.history.created"),
            constants.DATETIME_TYPE
        ),
        createCallbackField(
            "data.old_data.state.id",
            t("intermediaries.fields.from_state"),
            constants.STRING_TYPE,
            (value) => t("intermediaries.constants.state." + value)
        ),
        createCallbackField(
            "data.new_data.state.id",
            t("intermediaries.fields.to_state"),
            constants.STRING_TYPE,
            (value) => t("intermediaries.constants.state." + value)
        ),
        createField(
            "user.name",
            t("bookings.fields.history.username"),
            constants.STRING_TYPE
        ),
    ]

    if (!entity) {
        return null
    }

    return (
        <CollectionDataTable
            fetcher={fetcher}
            columns={columns}
        />
    )

}

export default IntermediaryEntityActionLogs