import {getActions as getRowActions} from "../../../Tags/TagPage/TagPage.actions";
import userPermissions from "../../../../lib/user-permissions";
import RouteForm from "../RouteForm";


export const getActions = (apiClient, t, navigate, route) => [
    {
        id: "add_route",
        title: t("common.create"),
        withDialog: {
            title: t("routes.actions.add.modal_title"),
            content: RouteForm
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.activityManager.route.addRoute(data)
                .then((result) => {
                    onSuccess(
                        t("routes.actions.add.confirm", {
                            name: result.name,
                        })
                    )
                })
                .catch((error) => {
                    onError(
                        t("routes.actions.add.error", {
                            name: data.name,
                        })
                    )
                })
        },
        condition: () => route.hasAnyPermission([
            userPermissions.PERM_PICKUP_POINTS_ADMINISTER,
            userPermissions.PERM_PICKUP_POINTS_ADMINISTER_OWN
        ])
    }
]


export const getTableActions = (...props) => getRowActions(...props)
