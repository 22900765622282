import { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import { reducer } from './reducers/volcano-auth.reducer';
import { useVolcanoApiClient } from "./VolcanoApiClientProvider"
import { VolcanoAuthContext } from "./VolcanoAuthContext"
import { initialVolcanoAuthState } from "./VolcanoAuthState"
import availableRoutes from "../routes/availableRoutes"
import User from "@volcanoteide/volcanoteide-api-client/dist/model/entity/user"
import _has from "lodash/has"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { useTranslation } from "react-i18next"
import { modulesMenu } from "../routes/mainMenu";

/**
 * Process available routes for an user.
 * 
 * @param {User} user 
 * @returns User processed user
 */
const processAvailableRoutes = (user) => {
    // check user has access to disfrutare
    if (!user.isIntermediary() && _has(user, "settings.disfrutare.base_url")) {
        user.permissions["disfrutare-access"] = { id: -1, name: "disfrutare access" }
        user.settings.disfrutare.url = (user.settings.disfrutare.base_url + user.settings.disfrutare.login_url).replace("[TOKEN]", localStorage.getItem("jwt"))
    }

    if (_get(user, "crm_intermediary.intermediary_type.id", '') !== 'provider') {
        user.permissions["manuals-access"] = { id: -2, name: "manuals access" }
    }

    // filter available routes for user
    const availableRoutesKeys = Object.keys(availableRoutes);
    user.allowedRoutes = availableRoutesKeys.reduce((acc, route) => {
        // check if user has permissions for this route
        if (availableRoutes[route].permissions && availableRoutes[route].permissions.length) {
            let access = availableRoutes[route].permissions
                .filter(perm => user.hasPermission(perm))
                .length

            // special cases
            if (user.site.id === 5) {
                const excludeRoutes = ["intermediary_offices", "billing_invoices", "liquidations"]
                if (excludeRoutes.includes(route)) {
                    access = false
                }
            }

            // only allow booking widget for intermediary users
            if (false && route.includes("bookings_add") && !user.isIntermediary()) {
                access = false
            }

            // only allow one click booking for configured users
            if (route.includes("bookings_ocb") && (!_has(user.settings, "ocb_products") || _isEmpty(user.settings.ocb_products))) {
                access = false
            }

            if (access) {
                return [
                    ...acc,
                    route
                ];
            } else {
                return acc
            }
        } else {
            return [
                ...acc,
                route
            ];
        }
    }, [])

    // filter available modules for user
    user.allowedModules = modulesMenu
        .filter(module => {            
            if (user.isIntermediary()) {
                return module.key === "intermediaries"
            }

            if (module.key === "intermediaries") {
                return false
            }

            // check if at least one route within module is allowed (tow level menu)
            const moduleRoutes = module.items.map(item => item.items.map(subitem => subitem.key)).flat()
            return moduleRoutes.some(route => user.allowedRoutes.includes(route))
        })
        .map(module => module.key)

    return user
}

const VolcanoAuthProvider = ({ children }) => {
    const { i18n } = useTranslation("vbms")
    const { apiClient, siteConfig, reloadSiteConfig } = useVolcanoApiClient()
    const [state, dispatch] = useReducer(reducer, initialVolcanoAuthState)
    const [ready, setReady] = useState(false)

    const loadUserRelatedData = useCallback((data, event) => {
        // load user settings
        apiClient.vbms.getUserSettings(data.user.id)
            .then((userSettings) => {
                data.user.settings = userSettings.config

                if (i18n.language !== data.user.settings.language) {
                    i18n.changeLanguage(data.user.settings.language)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                localStorage.setItem("jwt", data.token)
                data.user = processAvailableRoutes(data.user)
                localStorage.setItem("user", JSON.stringify(data.user))

                apiClient.setDefaultParam('enterprise_id', data.user.corporate_account.enterprise.id)

                dispatch({
                    type: event,
                    user: data.user,
                    redirect: data.redirect ?? null
                })
            })
    }, [apiClient, i18n])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get("vbms_token")

        if (token && localStorage.jwt !== token) {
            // remove user from local storage
            localStorage.removeItem("user")
            localStorage.removeItem("jwt")

            // remove active module 
            localStorage.removeItem("activeModule")

            // remove all cached data
            localStorage.removeItem("cacheStore")

            // reset api client (remove token from config)
            apiClient.logout()

            // initialize application from token query param
            apiClient.setToken(token)
            apiClient.user.getProfile()
                .then((user) => {
                    loadUserRelatedData({ token: token, user: user, redirect: urlParams.get("redirect") }, 'INITIALISE')
                })
                .catch(error => {
                    dispatch({ type: 'ERROR', error: error.message })
                })
        } else if (localStorage.user) {
            const user = new User(JSON.parse(localStorage.user))

            apiClient.setDefaultParam('enterprise_id', user.corporate_account.enterprise.id)

            // Checking user language in case of re-renders
            if (i18n.language !== user.settings.language) {
                i18n.changeLanguage(user.settings.language)
            }

            dispatch({
                type: 'INITIALISE',
                user: user,
            })
        }

        setReady(true)
    }, [apiClient, loadUserRelatedData])

    const authenticate = useCallback(
        (credentials) => {
            apiClient
                .authenticate(credentials.username, credentials.password, siteConfig.corporate_account)
                .then((data) => {
                    if (data.user.roles.length === 1 && data.user.roles[0].slug === 'authenticated-user') {
                        dispatch({ type: 'ERROR', error: 'Forbidden access' })
                    } else {
                        loadUserRelatedData(data, 'INITIALISE')
                    }
                })
                .catch(error => {
                    dispatch({ type: 'ERROR', error: error.message })
                })

        },
        [apiClient, siteConfig, loadUserRelatedData]
    )

    const resetPasswordRequest = useCallback(
        async (email) => {
            return apiClient
                .resetPasswordRequest(email, siteConfig.corporate_account)
                .then(() => {
                    dispatch({ type: 'RESET_PASSWORD_REQUEST' })
                    return true
                })
                .catch(error => {
                    dispatch({ type: 'ERROR', error: error.message })
                    return false
                })
        },
        [apiClient, siteConfig]
    )

    const resetPassword = useCallback(
        async (data) => {
            return apiClient
                .resetPassword(data)
                .then(() => {
                    dispatch({ type: 'RESET_PASSWORD' })
                    return true
                })
                .catch(error => {
                    dispatch({ type: 'ERROR', error: error.message })
                    return false
                })
        },
        [apiClient]
    )

    const logout = useCallback(
        () => {
            // remove user from local storage
            localStorage.removeItem("user")
            localStorage.removeItem("jwt")

            // remove active module 
            localStorage.removeItem("activeModule")

            // remove all cached data
            localStorage.removeItem("cacheStore")

            // reset api client (remove token from config)
            apiClient.logout()

            reloadSiteConfig()

            dispatch({ type: 'LOGOUT' })
        },
        []
    )

    const tokenExists = useCallback(
        () => {
            const token = localStorage.getItem("jwt")
            return !!token
        },
        []
    )

    const tokenRenew = useCallback(
        (enterpriseId) => {
            return apiClient.renewToken(enterpriseId).then((token) => {
                localStorage.setItem("jwt", token)

                // remove all cached data
                localStorage.removeItem("cacheStore")

                return apiClient.user.getProfile().then((user) => {
                    loadUserRelatedData({ token: token, user: user }, 'RENEW_TOKEN')
                    reloadSiteConfig()

                    return { success: true }
                })
            }).catch((error) => {
                return { success: true, error: error }
            })
        },
        [apiClient, loadUserRelatedData, reloadSiteConfig]
    )

    const isTokenValid = useCallback(
        () => {
            const token = localStorage.getItem("jwt")
            if (!token) {
                return false
            }

            try {
                const payload = JSON.parse(atob(token.split('.')[1]))
                const now = Math.floor(Date.now() / 1000)
                return now < payload.exp
            } catch (e) {
                return false
            }
        },
        []
    )

    const clearRedirect = useCallback(() => {
        dispatch({ type: 'CLEAR_REDIRECT' })
    }, [])

    const contextValue = useMemo(() => {
        return {
            ...state,
            authenticate,
            logout,
            resetPasswordRequest,
            resetPassword,
            tokenExists,
            tokenRenew,
            isTokenValid,
            clearRedirect,
        }
    }, [
        state,
        authenticate,
        logout,
        resetPasswordRequest,
        resetPassword,
        tokenExists,
        tokenRenew,
        isTokenValid,
        clearRedirect,
    ])

    return (
        <>
            {ready && <VolcanoAuthContext.Provider value={contextValue}>{children}</VolcanoAuthContext.Provider>}
        </>
    )
}

export default VolcanoAuthProvider